.flex-container-home {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.flex-container-home .flex-content-container-home .intro-container-home, .flex-container-home .flex-content-container-home .thanks-container-home {
    margin: 25px auto auto auto;
    /* padding-bottom: 4em;  */
}

.flex-container-home .scratch-container-home {
    margin: 2em auto auto; }
.flex-container-home .scratch-container-home .scratch-header-home h2 {
    font-family: "Oswald", sans-serif;
    text-align: center;
    color: #fff;
    font-weight: 600;
    font-size: 2em;
    line-height: 1; }

.flex-container-home .button-wrapper {
    display: flex;
    justify-content: center; }

.flex-container-home .button-item-container {
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 4em; }
.flex-container-home .button-item-container .btn-scratcher {
    border-radius: 0 !important;
    bottom: 0;
    font-size: 1.8em;
    font-weight: 800;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    font-family: "Oswald", sans-serif;
    color: #000000;
    background-color: #0000ff;
    text-transform: uppercase; }

.flex-content-container-home {
    display: flex;
    flex-direction: column;
    flex: auto;
    overflow: hidden;
}

.intro-container-home {
    margin: 0 auto auto;
    text-align: center;
}

.hero-text-container {
    padding-bottom: 1em;
    padding-top: 1em;
    margin: auto; }
.hero-text-container img.main-hero-image, .hero-text-container img.main-hero-image:after {
    zoom: 45%; }
.hero-text-container .thanks-main-title {
    color: #0000ff;
    font-family: "Oswald", sans-serif;
    font-weight: bold;
    padding: 0 1em 0 1em; }
.hero-text-container .thanks-main-title h1 {
    text-shadow: none;
    font-size: 9vmax;
    line-height: 1em; }
.hero-text-container .thanks-main-title .intro-button-wrapper {
    text-align: center; }
.hero-text-container .login-main-subtitle, .hero-text-container .thanks-main-subtitle {
    color: white;
    text-align: center;
    font-size: 6vmax;
    font-family: "Oswald", sans-serif;
    font-weight: 600; }
.hero-text-container .login-main-subtitle {
    padding: 0 1em 0 1em; }
.hero-text-container .thanks-main-subtitle {
    padding: 0 0.5em 0 0.5em; }

.question-box #create-email-form label {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: 300;
}

.question-box {
    background-color: white;
}

.btn.btn-play {
    margin-top: 10px;
    color: #fff;
    background-color: #cc172c;
    width: 250px;
    font-size: 1.4em;
    font-family: "Oswald", sans-serif;
    height: 50px;
    font-weight: 700;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-rules {
    margin-top: 15px;
    color: #fff;
    background-color: #000;
    width: 120px;
    font-size: 15px;
    font-family: "Oswald", sans-serif;
    height: 40px;
    font-weight: 700;
    box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
}

.btn.btn-rules:hover {
    color: #fff;
}

label p {
    margin-top: 15px;
}

@media (min-width: 600px) {
    .intro-container-home {
        margin: 5% auto auto;
    }
}

@media  (max-width: 600px) {
    .flex-container-home h2 {
        font-size: 1.5em;
    }
    .btn.btn-play {
        width: 150px
    }
}
